<template>
    <div style="text-align: left;">
        <el-card shadow="never" style="margin-bottom: 25px;">
            <div slot="header" class="my-header">
                <div>产品回收站 <el-input v-model="spuName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style="width: 150px;"></el-input>
                    <el-button type="default" size="mini" @click="search">搜索</el-button>
                </div>
            </div>
            <div>
                <template>
                    <el-table :data="prlductData.content" style="width: 100%">
                        <el-table-column type="index" label="编号">
                        </el-table-column>
                        <el-table-column prop="date" label="产品封面" width="100">
                            <template slot-scope="scope">
                                <el-avatar shape="square" :src="scope.row.cover" v-if="hasImportImg(scope.row.cover)"></el-avatar>
                                <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cover" v-else></el-avatar>
                            </template>
                        </el-table-column>
                        <el-table-column prop="spuName" label="产品名称/所属品牌">
                            <template slot-scope="scope">
                                <div class="pointer" @click="$oucy.go('/product?spuId='+scope.row.id)">{{scope.row.spuName}}</div>
                                <div class="c-2" v-if="scope.row.brand && scope.row.brand.brandName">{{scope.row.brand.brandName}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="brand.brandName" label="所属分类">
                            <template slot-scope="scope">
                                <span v-for="item in classifyDatas" :key="item.id" v-if="scope.row.classify.id==item.id">{{item.classifyName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="80" prop="spuRankingWeight" label="排序">
                        </el-table-column>
                        <el-table-column prop="quantity" width="80" label="库存">
                        </el-table-column>
                        <el-table-column width="80" prop="spuSoldAmount" label="销量">
                        </el-table-column>
                        <el-table-column prop="spuPrise" label="当前价格">
                            <template slot-scope="scope">
                                <span class="spuPrise" v-if="scope.row.spuPrise=='0.00'">面议</span>
                                <span class="spuPrise" v-else>￥{{scope.row.spuPrise}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="isShelves" label="申请日期/状态">
                            <template slot-scope="scope">
                                <div>{{scope.row.spuCreatetime}}</div>
                                <span v-if="scope.row.isShelves" class="putAway">已上架</span>
                                <span v-else class="soldOut">已下架</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="200" label="操作">
                            <template slot-scope="scope">
                                <el-button type="danger" size="mini" @click="delMessageBox('deepDeleteFurnitureSpuById',scope.row)">彻底删除</el-button>
                                <el-button type="default" size="mini" @click="recoverDeleteFurnitureSpuById(scope.row)">恢复</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div>
                    <el-divider></el-divider>
                    <div class="df-jc-s-b">
                        <div>
                        </div>
                        <el-pagination v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import oucy from "../../../util/oucyUtil";
import { Notification } from "element-ui";
import { furnitureClassify, furnitureGlobalBrand, spu, globalClassify } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"
export default {
    name: "ProductRecycle",
    data() {
        return {
            classifyDatas: [],
            limit: 10,
            start: 0,
            prlductData: {
                content:[]
            },
            spuName: null,
            enterpriseId: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        const me = this;
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        } else {
            this.$oucy.replace('/acc/enterprise')
        }
        furnitureClassify.findByLtUserAuthAndClassifyCategory({ enterpriseId: this.enterpriseId }).then(res => {
            me.classifyDatas = res||[];
        });

        this.getAllRecycleSpu()
    },
    methods: {
        // 删除弹框
        delMessageBox(fu, v, text, i) {
            this.$confirm(text || '此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this[fu](v, i)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch((err) => {
                console.log(err)
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        // 产品列表
        // 
        search() {
            // this.limit=10
            this.start = 0
            this.getAllRecycleSpu()
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.getAllRecycleSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.getAllRecycleSpu();
        },
        // 查询列表
        getAllRecycleSpu() {
            // /spu/getAllRecycleSpuClient
            spu.getAllRecycleSpu({
                spuName: this.spuName,
                limit: this.limit,
                start: this.start,
                enterpriseId: this.enterpriseId
            }).then(res => {
                console.log(res)
                this.prlductData = res||{}
            });
        },
        // 彻底删除
        deepDeleteFurnitureSpuById(v) {
            spu.deepDeleteFurnitureSpuById({
                spuId: v.id
            }).then(res => {
                this.getAllRecycleSpu()
            });
        },
        // 恢复
        recoverDeleteFurnitureSpuById(v) {
            spu.recoverDeleteFurnitureSpuById({
                spuId: v.id
            }).then(res => {
                this.getAllRecycleSpu()
            });
        },
        hasImportImg(v) {
            return v && v.startsWith('//')
        },


    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.brand-flex-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.brand-container {
    width: 200px;
    height: 240px;
    position: relative;
    margin: 0 10px 10px 0;
}

.brand-container-top {
    text-align: center;
}

.brand-container-top img {
    width: 100px;
    height: 100px;
}

.brand-container-top-txt {
    font-size: 10px;
    color: #686869;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.brand-container-button {
    padding: 14px 0 0 0;
    text-align: center;
}

.brand-status {
    position: absolute;
    top: 0;
    right: 0;
}

.spuPrise {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ED2F2F;
}

.putAway,
.soldOut {
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding: 4px 10px 4px 10px;
    display: inline-block;
}

.putAway {
    background: #EAF9E3;
    color: #69A220;
}

.soldOut {
    background: #F0F0F0;
    color: #C7C7C7;
}

/*分类*/
.classifyDatasListItems {
    border: 1px solid #F2F4F6;
    /*padding: 0 15px;*/
}

.classifyDatasListItem {
    display: flex;

}

.classifyDatasListItem+.classifyDatasListItem {
    border-top: 1px solid #F2F4F6;

}

.classifyDatasListLeft {
    padding: 15px;
    border-right: 1px solid #F2F4F6;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;

}

.classifyDatasListRight {
    padding: 15px 0;
}
</style>